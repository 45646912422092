// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//     production: false,
//     firebaseConfig: {
//         apiKey: "AIzaSyAo7YDMDEgYzyJSSmsJeqc02Ln_fyDZ7n4",
//         authDomain: "sunbox-app-test.firebaseapp.com",
//         projectId: "sunbox-app-test",
//         storageBucket: "sunbox-app-test.appspot.com",
//         messagingSenderId: "596689857953",
//         appId: "1:596689857953:web:737e84e0b4616994e9afe0",
//         measurementId: "G-9CFCD9B77X",
//         cloudFunctionsUrl: 'https://us-central1-sunbox-app-test.cloudfunctions.net'
//     },
//     stripePublicKey: "pk_test_51HVkV0CdhpikWHDwffnBh9sLp9EnPduLQttpQScapthQRTGVAThkkzHgJhplm43HAJJNQ7sLYwyM9PMX5IwArE5000F0xxWIrE",
// };


export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyB5hz2IhKYPbcz1Kjxyiyk5h6ZitphIpCI",
        authDomain: "sunbox-app.firebaseapp.com",
        projectId: "sunbox-app",
        storageBucket: "sunbox-app.appspot.com",
        messagingSenderId: "720329741242",
        appId: "1:720329741242:web:fa1033a42acf380a4e4383",
        measurementId: "G-2QBYDGY93S",
        cloudFunctionsUrl: 'https://us-central1-sunbox-app.cloudfunctions.net'
    },
    stripePublicKey: "pk_live_51Kxfu8FaWiICT1gLqd5z3polpoMp1hdh7MRvQlGrc9tk7oZc7DgbFXKNbA0MhBTEGTkSHs12MP0PqxHAQ8n52qyM00T5c8vpbl",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
